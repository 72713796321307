import * as React from 'react';
import {
  Badge,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Close, Tune } from '@material-ui/icons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateFiltrosTurma } from '~/store/modules/new-contract/actions';

const days = [
  { abbr: 'Seg', name: 'Segunda' },
  { abbr: 'Ter', name: 'Terça' },
  { abbr: 'Qua', name: 'Quarta' },
  { abbr: 'Qui', name: 'Quinta' },
  { abbr: 'Sex', name: 'Sexta' },
  { abbr: 'Sab', name: 'Sábado' },
];

const periodos = ['Manhã', 'Tarde', 'Noite'];

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    width: '350px',
    padding: '20px',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: '5px',
  },
}));

export default function Filtros() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusTurmaLookup = useSelector(state => state.lookup.statusTurma);
  const tipoTurmaLookup = useSelector(state => state.lookup.tipoTurma);
  const filtrosAplicados = useSelector(state => state.newContract.filtrosTurma);
  const contrato = useSelector(state => state.newContract.dadosContrato);
  const activedCourses = useSelector(state => state.cursos.actived);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      dias: [],
      periodos: [],
      status: [],
      tipo: [],
      ...filtrosAplicados,
    },
    onSubmit: values => {
      dispatch(updateFiltrosTurma(values));
      handleClose();
    },
  });

  const handleClean = () => {
    formik.resetForm();
    formik.handleSubmit();
  };

  const renderSelectedDays = () => {
    const daysSelected = [];

    days
      .filter(day => formik.values.dias.includes(day.abbr))
      .map(day => daysSelected.push(day.name));

    return daysSelected.join(', ');
  };

  const renderSelectedStatus = () => {
    const statusSelected = [];

    statusTurmaLookup
      .filter(status => formik.values.status.includes(status.intkey))
      .map(status => statusSelected.push(status.descricao));

    return statusSelected.join(', ');
  };

  const renderSelectedTipo = () => {
    const tiposSelected = [];

    tipoTurmaLookup
      .filter(tipo => formik.values.tipo.includes(tipo.intkey))
      .map(tipo => tiposSelected.push(tipo.descricao));

    return tiposSelected.join(', ');
  };

  const renderSelectedCourses = () => {
    const descricoes = [];

    activedCourses
      .filter(curso => formik.values.cursos.includes(curso.curso_id))
      .map(curso => descricoes.push(curso.descricao));

    return descricoes.join(', ');
  };

  const renderSelectedPeriodos = () => {
    const descricoes = [];

    periodos
      .filter(periodo => formik.values.periodos.includes(periodo))
      .map(periodo => descricoes.push(periodo));

    return descricoes.join(', ');
  };

  const getQuantidadeFiltrosAplicados = () => {
    let quantidade = 0;

    Object.keys(filtrosAplicados)
      .filter(filterName => filterName !== 'conteudos')
      .forEach(filtro => {
        if (Array.isArray(filtrosAplicados[filtro])) {
          quantidade += filtrosAplicados[filtro].length;
        } else if (filtrosAplicados[filtro]) {
          quantidade += 1;
        }
      });

    return quantidade;
  };

  return (
    <div>
      <Badge badgeContent={getQuantidadeFiltrosAplicados()} color="primary">
        <Button
          variant="text"
          color="secondary"
          startIcon={<Tune color="primary" />}
          onClick={handleOpen}
        >
          Filtrar por
        </Button>
      </Badge>
      <Modal open={open} className={classes.modal}>
        <div className={classes.paper}>
          <div className={classes.title}>
            <Typography variant="h6" color="primary">
              Filtrar por
            </Typography>
            <IconButton size="small" onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <FormControl
              fullWidth
              variant="filled"
              disabled={contrato.cursos.length === 1}
            >
              <InputLabel id="cursos-label">Cursos</InputLabel>
              <Select
                labelId="cursos-label"
                multiple
                name="cursos"
                value={formik.values.cursos}
                onChange={formik.handleChange}
                renderValue={renderSelectedCourses}
                variant="filled"
              >
                {activedCourses
                  .filter(curso => contrato.cursos.includes(curso.curso_id))
                  .map(curso => (
                    <MenuItem key={curso.curso_id} value={curso.curso_id}>
                      <Checkbox
                        checked={
                          formik.values.cursos.indexOf(curso.curso_id) > -1
                        }
                      />
                      <ListItemText primary={curso.descricao} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel id="dias-semana-label">Dias da semana</InputLabel>
              <Select
                labelId="dias-semana-label"
                multiple
                name="dias"
                value={formik.values.dias}
                onChange={formik.handleChange}
                renderValue={renderSelectedDays}
                variant="filled"
              >
                {days.map(day => (
                  <MenuItem key={day.abbr} value={day.abbr}>
                    <Checkbox
                      checked={formik.values.dias.indexOf(day.abbr) > -1}
                    />
                    <ListItemText primary={day.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="filled" fullWidth margin="normal">
              <InputLabel id="periodo-label">Períodos</InputLabel>
              <Select
                labelId="periodo-label"
                multiple
                value={formik.values.periodos}
                onChange={formik.handleChange}
                renderValue={renderSelectedPeriodos}
                variant="filled"
                name="periodos"
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {periodos.map(periodo => (
                  <MenuItem key={periodo} value={periodo}>
                    <Checkbox
                      checked={formik.values.periodos.indexOf(periodo) > -1}
                    />
                    <ListItemText primary={periodo} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                multiple
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                renderValue={renderSelectedStatus}
                variant="filled"
              >
                {statusTurmaLookup.map(status => (
                  <MenuItem key={status.intkey} value={status.intkey}>
                    <Checkbox
                      checked={formik.values.status.indexOf(status.intkey) > -1}
                    />
                    <ListItemText primary={status.descricao} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel id="tipo-label">Tipo</InputLabel>
              <Select
                labelId="tipo-label"
                multiple
                name="tipo"
                value={formik.values.tipo}
                onChange={formik.handleChange}
                renderValue={renderSelectedTipo}
                variant="filled"
              >
                {tipoTurmaLookup.map(tipo => (
                  <MenuItem key={tipo.intkey} value={tipo.intkey}>
                    <Checkbox
                      checked={formik.values.tipo.indexOf(tipo.intkey) > -1}
                    />
                    <ListItemText primary={tipo.descricao} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={classes.buttons}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleClean}
              >
                Limpar
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
              >
                Filtrar
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
